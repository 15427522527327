/* .tableHead {
  position: sticky;
  top: 0;
} */

.number_Input_Control,
.select_Input_Control,
.html_btn,
.previous_pattern_select_Input {
  text-align: center;
  height: 25px;
  border-radius: 4px;
  outline: none;
  /* border: none; */
}

.html_btn {
  cursor: pointer;
}

.number_Input_Control {
  
}

.previous_pattern_select_Input {
  /* padding: 4px; */
  /* padding-left: 4px; */
  text-align: left;
  /* width: 120px; */
  border: 1px solid #ccc;
  margin-bottom: 4px;
}

.small_font_date {
  font-size: 12px;
  min-width: 80px;
}

.sortIcon {
  width: 25px;
  height: 25px;
  padding: 3px;
  
  background-color: white;
  border-radius: 50%;
  cursor: pointer;
}

.flexCenter {
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon_container {
  cursor: pointer;
}

.recom_span {
  cursor: pointer;
  border: 1px solid #339502;
  background-color: #fff;
  padding: 0 4px;
  margin: 2px;
  border-radius: 4px;
  display: inline-block;
  
  
}
.recom_span.disabled {
  pointer-events: none;
}
.recom_span:hover {
  background-color: #777;
  color: #fff;
}

.blinking_border,
.clearAllFiltersBtn {
  border-radius: 4px;
}

.blinking_border {
  border: 2px #ff0000 solid;
  animation: blink 1s;
  animation-iteration-count: infinite;
}

.clearAllFiltersBtn {
  /* border: none; */
  outline: none;
  padding: 0.2rem;
  font-size: 1rem;
  letter-spacing: 2px;
  /* color: #fff; */
  /* background-color: rgb(235, 146, 113); */
  text-transform: uppercase;
  cursor: pointer;
}


.symbolLink {
  padding: 0 0.4rem;
  font-size: 0.8rem;
  letter-spacing: 1px;
  /* font-weight: 300; */
  color: #333;
  border: 1px solid #333;
  border-radius: 0.2rem;
  /* background-color: rgb(129, 228, 79); */
  background-color: #e2e2e2;
}
.symbolLink:hover {
  color: #fff;
  background-color: #333;
}

@keyframes blink {
  50% {
      border-color: #fff;
    }
}



/* TABLE TOP SETTINGS CONTAINER */
.table_settings_container {
  max-height: 250px;
  /* border: 0.5px solid red; */
  margin: 1rem 0;
  /* padding-top: 2rem; */
  /* padding-bottom: 1rem; */
  /* padding-left: 3rem; */
}



/* MEDIA QUERIES */
/* Small Screens 650 & below */
@media only screen and (max-width: 650px) {
  /* body {
    background-color: lightblue;
  } */
}