
.activeTopDetailsContainer {
  margin: 2rem;
  padding: 2rem 1rem;
  max-width: 350px;
  border: 1px solid #ccc;
  border-radius: 0.5rem;
}

.activeTopDetailsContainer h2 {
  text-align: center;
  margin-bottom: 1rem;
}

.detailItem {
  display: flex;
  align-items: center;
  justify-content: space-between;

  border-bottom: 1px solid #ccc;
}
.detailItem h3 {
  font-weight: 300;
  text-transform: uppercase;
}