
:root {
  /* --main-bg-color: brown; */
  --border-radius: 0.2rem;
  --border-dark: 1px solid #999;
  --border-light: 1px solid #ccc;
}

.mainHeading {
  text-transform: uppercase;
  text-align: center;
}

.createPostWrapper {
  /* min-width: 800px;
  padding: 1rem; */
}



.instructionsContainer {
  margin: 1rem 0;
  padding: 0.2rem 0.4rem;
  display: inline-block;
  border: var(--border-light);
  border-radius: var(--border-radius);

  /* background-color: #FAC898; */
  background-color: #F2D0D9;
  
}

.instructionsContainer ul {
  list-style: none;
}

.instructionsContainer p,
.instructionsContainer li {
  color: #333;
  /* color: #D24545; */
  font-size: 0.8rem;
  /* font-weight: bold; */
  text-transform: uppercase;
}

.createPostBtn,
.updateBtn {
  cursor: pointer;
  outline: none;
  border: var(--border-dark);

  padding: 0.2rem;
  margin-top: 0.5rem;
  border-radius: var(--border-radius);

  text-transform: uppercase;
  font-weight: bold;
  color: #333;
}