@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200;300;400;500;600;700&family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');


.TermsAndConditions,
.RefundsAndCancellation,
.PrivacyPolicy {
  font-family: 'Oswald', 'Rubik', sans-serif;
  font-weight: 300;
  font-size: 18px;
  text-align: justify;
}

.TermsAndConditions h1,
.RefundsAndCancellation h1,
.PrivacyPolicy h1 {
  margin: 1rem 0;
}

.TermsAndConditions h2,
.RefundsAndCancellation h2,
.PrivacyPolicy h2 {
  margin: 2rem 0 0.5rem;
}

.TermsAndConditions ul,
.RefundsAndCancellation ul,
.PrivacyPolicy ul {
  /* list-style: none; */
  margin-bottom: 0.5rem;
}

.TermsAndConditions p,
.RefundsAndCancellation p,
.PrivacyPolicy p {
  margin-bottom: 1rem;
}