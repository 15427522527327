.user_Type_Select_Input {
  outline: none;
  padding: 4px;
  text-align: left;
  width: 140px;
  /* border: 2px solid red; */
  border-radius: 4px;
}

user_Type_Select_Input:hover,
user_Type_Select_Input:focus,
user_Type_Select_Input:active,
user_Type_Select_Input:checked {
  background: linear-gradient(#5A2569, #5A2569);
  color: red;
  border: 1px solid red;
}


.html_btn {
  text-align: center;
  height: 25px;
  border-radius: 4px;
  outline: none;
  border: none;

  cursor: pointer;
  background-color: red;
  color: white;
}