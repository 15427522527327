

.pageContainer {
  margin: 2rem;
}

.backBtn {
  padding: 0 0.5rem;
  margin-bottom: 1rem;
  cursor: pointer;
  /* background-color: greenyellow; */
}


/* SEARCH */
.searchContainer {
  margin-bottom: 2rem;
  /* display: inline-block; */
  max-width: 650px;
  padding: 1rem;
  border: 1px solid #ccc;
  border-radius: 0.2rem;

  display: flex;
  justify-content: space-between;
}

.searchListContainer {
  min-width: 300px;
  border: 1px solid #ccc;
  padding: 0.5rem;
  /* display: flex; */
  /* gap: 1rem; */
  /* justify-content: space-between; */
}
.searchInputContainer {
  margin: 0.5rem 0;
  display: flex;
  justify-content: space-between;

}

/* SEARCH BY DATE RANGE CONTAINER */
.dateRangeContainer {
  min-width: 300px;
  border: 1px solid #ccc;
  /* margin-top: 1rem; */
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
.dateRangeContainer h4 {
  margin-bottom: 0.5rem;
  text-transform: uppercase;
  font-size: 0.8rem;
  font-weight: 500;
}

.startDateContainer,
.endDateContainer {
  display: flex;
  justify-content: space-between;
}


.pageTitle {
  margin-bottom: 2rem;
}

.tableContainer {
  max-width: 1200px;
}

.table {
  border-collapse: collapse;
  /* width: 100%; */
}

/* Table Header Styles */
.table thead {
  background-color: #f2f2f2;
}


/* Table header & body styles */
.table th,
.table td {
  padding: 6px 6px;
  text-align: left;
  border: 1px solid #dddddd;
}
