.tableContainer {
  width: 100%;
  max-width: 1000px;
  border-collapse: collapse;
  border: 1px solid #ccc;
  /* background-color: red; */
}

.tableContainer th,
.tableContainer td {
  padding: 8px;
  text-align: left;
  border: 1px solid #ccc;
}

.tableContainer th {
  background-color: #f2f2f2;
}

.tableBodyContainer tr:nth-child(even) {
  background-color: #f2f2f2;
}

.imgPreviewBox {
  width: 100px;
}

.imgPreview {
  width: 100%;
}

.container {
  margin: 15px;
}

.title {
  margin: 10px;
}
.deleteIcon {
  width: 20px;
  height: 20px;
  fill: var(--danger-color);
  cursor: pointer;
  transition: transform 0.2s ease, fill 0.2s ease;
}

.deleteIcon:hover {
  transform: scale(1.2);
  fill: #a71d2a;
}
