
.outerWrapper {
  width: 300px;
  height: 300px;
  padding: 2rem;
  /* border: 1px solid red; */
  border-radius: 0.4rem;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

}

.innerWrapper {
  /* border: 1px solid green; */
  display: flex;
  gap: 1rem;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}


.image {
  width: 60px;
  border-radius: 50%;
}

h2.title {
  font-size: 2rem;
  text-align: center;
}

.description {
  text-align: center;
  font-weight: 300;
  color: rgba(86, 86, 86, 1);

}