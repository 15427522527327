:root {
  --primary-color: #007bff;
  --secondary-color: #6c757d;
  --danger-color: #dc3545;
  --bg-color: #f8f9fa;
  --text-color: #333;
  --border-color: #ddd;
}
.formPreviewContainer {
  /* border: 1px solid red; */
  max-width: 1100px;
  padding: 1rem;
  background-color: #fff;
  display: flex;
}

.bannerForm {
  flex: 2;
  border: 1px solid gray;
  padding: 1rem;
}

.inputBox {
  /* border: 1px solid red; */
  margin: 1rem 0;
  display: flex;
  justify-content: space-between;
}

.inputControl,
.imgUploadContainer {
  border: 1px solid #ccc;
  padding-left: 0.5rem;
  border-radius: 0.2rem;
}
.inputControl {
  width: 70%;
  outline: none;
  /* padding: 0.2rem; */
  /* border: 1px solid #ccc;
  border-radius: 0.2rem; */
}

.imgUploadContainer {
  display: inline-block;

  width: 70%;
  display: flex;
  /* align-items: center;
  justify-content: center; */
}

.imgUrlInput {
  border: none;
  outline: none;
  width: 100%;
}

.imgFileInput {
  display: none;
}

/* IMAGE PREVIEW CONTAINER */
.imgPreviewContainer {
  /* max-width: 700px; */
  padding: 1rem;
  border: 1px solid gray;
  flex: 3;

  /* display: flex;
  align-items: center;
  justify-content: center; */
}

.imgContainer {
  padding-top: 1rem;
  /* width: 300px */
  /* object-fit: contain; */
  /* border: 2px solid #333; */
  border-radius: 0.5rem;
}

.imgPreview {
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 0.5rem;
}

.uploadBtn {
  /* background-color: #ccc; */
  display: inline-block;
  text-transform: uppercase;
  font-size: 0.9rem;
  /* border: 1px solid #ccc; */
  padding: 0 0.2rem;
  margin-top: 0 0.2rem;
  border-radius: 0.2rem;
  cursor: pointer;
  color: blue;
}
.uploadBtn:hover {
  background-color: blue;
  color: white;
}

.btnContainer {
  display: flex;
  /* align-content: center; */
  justify-content: center;
}

.submitBtn {
  border: none;
  outline: none;
  margin-top: 1rem;
  padding: 0.3rem 0.5rem;
  background-color: greenyellow;
  font-size: 1.1rem;
  border-radius: 0.4rem;
  text-transform: uppercase;
  cursor: pointer;
}
.btnContainer {
  display: flex;
  gap: 1rem;
  margin-top: 1rem;
}

.createContentBtn {
  padding: 0.6rem 1.2rem;
  font-size: 1rem;
  color: #fff;
  background-color: var(--primary-color);
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.createContentBtn:hover {
  background-color: darken(var(--primary-color), 10%);
}

.createContentBtn:disabled {
  cursor: not-allowed;
}
