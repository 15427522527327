.user-wrappper{
  display: flex;
  flex-direction: column;
  margin: 20px;
}

.member-details-container {
  margin: 20px
}

.member-list{
  margin-top: 20px;
}

.team-members-divider-container {
  margin: 10px 0px
}