
.userSupportBox {
  --borderRadius: 0.2rem;

  min-width: 350px;
  padding: 1rem;
  border: 1px solid #ccc;
  border-radius: var(--borderRadius);
}

.userSupportBox h2 {
  text-align: center;
  margin-bottom: 1rem;
}

.subjectLabel,
.descriptionLabel {
  text-transform: uppercase;
}

.titleInput,
.descInput,
.fileInput {
  outline: none;
  width: 100%;
  margin: 0.4rem 0 1rem;
  border: 1px solid #ccc;
  border-radius: var(--borderRadius);
}

.titleInput {
  height: 30px;
}

.descInput {
  resize: none;
}

.submitButton {
  outline: none;
  margin: 0.5rem 0;
  padding: 0.2rem 1rem;
  text-transform: uppercase;
  cursor: pointer;
}

.submitButton:hover {
  background-color: #333;
  color: #fff;
}