.formPreviewContainer {
  /* border: 1px solid red; */
  max-width: 1400px;
  height: 500px;
  padding: 1rem;

  display: flex;
}

.bannerForm {
  flex: 1;
  border: 1px solid #000;
  padding: 1rem;
}

.inputBox {
  /* border: 1px solid red; */
  margin: 1rem 0;
  display: flex;
  justify-content: space-between;
}

.inputControl,
.imgUploadContainer {
  border: 1px solid #ccc;
  padding-left: 0.5rem;
  border-radius: 0.2rem;
}
.inputControl {
  width: 70%;
  outline: none;
  /* padding: 0.2rem; */
  /* border: 1px solid #ccc;
  border-radius: 0.2rem; */
}

.imgUploadContainer {
  display: inline-block;

  width: 70%;
  display: flex;
  /* align-items: center;
  justify-content: center; */
}

.imgUrlInput {
  border: none;
  outline: none;
  width: 100%;
}

.imgFileInput {
  display: none;
}

/* IMAGE PREVIEW CONTAINER */
/* .imgPreviewContainer {
  border: 1px solid #333;
  flex: 3;

  display: flex;
  align-items: center;
  justify-content: center;
}

.imgContainer {
  width: 300px;
  object-fit: contain;
}

.imgPreview {
  object-fit: contain ;
} */
.imgPreviewContainer {
  border: 1px solid green;
  flex: 3;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 100%; /* Ensure the container doesn't exceed its parent width */
  max-height: 100%; /* Ensure the container doesn't exceed its parent height */
  overflow: hidden; /* Prevent content overflow */
}

.imgContainer {
  width: 100%;
  height: 500px; /* Specify height to match width for the container */
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden; /* Ensures image doesn't overflow */
}

.imgPreview {
  width: 100%;
  height: auto; /* Maintains aspect ratio */
  max-width: 100%; /* Ensures image doesn't exceed container width */
  max-height: 100%; /* Ensures image doesn't exceed container height */
  object-fit: contain; /* Maintains image aspect ratio */
}

.submitBtnContainer {
  margin: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.uploadBtn,
.submitBtn {
  /* background-color: #ccc; */
  display: inline-block;
  text-transform: uppercase;
  font-size: 0.9rem;
  border: 1px solid #ccc;
  padding: 0 0.2rem;
  border-radius: 0.2rem;
  cursor: pointer;
  color: blue;
}
.uploadBtn:hover,
.submitBtn:hover {
  background-color: blue;
  color: white;
}
