.select_Input {
  padding: 2px;
  text-transform: uppercase;
  font-weight: 300;
  border: 1px solid #999;
  border-radius: 4px;
  outline: none;
  /* color: #fff;
  background-color: #666; */
}

.select_Input option {
  font-weight: 300;
  
}


.blinking_border {
  border: 2px #ff0000 solid;
  border-radius: 4px;
  animation: blink 1s;
  animation-iteration-count: infinite;
}

@keyframes blink {
  50% {
      border-color: #fff;
    }
}
