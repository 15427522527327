.thanksContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #f5f5f5;
}

.thanksIcon {
  margin-bottom: 30px;
}

.thanksHeading {
  font-size: 36px;
  font-weight: bold;
  color: #333;
  margin-bottom: 15px;
}

.thanksMessage {
  font-size: 16px;
  color: #666;
  line-height: 1.5;
}