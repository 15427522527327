/* .tab {
  border: 1px solid black;
} */

.disclaimerWrapper {
  /* border: 1px solid gray; */
  /* padding: 0.5rem; */
  border-radius: 0.4rem;
}

.disclaimerWrapper h2 {
  text-align: center;
}

.disclaimerText {
  text-align: justify;
  
}




/* MEDIA QUERIES */
@media screen and (max-width: 480px) {
  .disclaimerText { font-size: 0.8rem; }
}