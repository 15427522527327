.searchContainer {
  /* border: 1px solid red; */
  min-width: 300px;
  display: flex;
  justify-content: space-between;
}

.searchInputBox {
  width: 80%;
  padding: 0.2rem;
  border: 1px solid #ccc;
  border-radius: 0.2rem;
  display: flex;
  align-items: center;
  gap: 0.2rem;
}

.searchInput {
  width: 100%;
  border: none;
  outline: none;
}

.cancelIcon {
  cursor: pointer;
}

.searchBtn {
  cursor: pointer;
  padding: 0.2rem;
}

.csvDownloadContainer {
  margin: 0.5rem;
  max-width: 400px;
  padding: 1rem;
  border: 1px solid #ccc;
}

.selectUserTypeContainer {
  margin: 0.5rem 0;
}

.dnloadUsertypeContainer {
  padding: 1rem;
  border: 1px solid #999;
  border-radius: 0.2rem;
}

/* Anchor Link button */
.downloadBtn {
  display: inline-block;
  padding: 0.5rem;
  margin-top: 0.5rem;
  /* margin: 1rem 0; */
  border-radius: 0.2rem;
  background-color: #ccc;
  color: #333;
  font-weight: bold;
}

.downloadBtn:hover {
  background-color: #333;
  color: #e2e2e2;
}

.horizontalLine {
  /* height: 8px; */
  border-bottom: 1px solid #ccc;
}
.selectDropdown {
  padding: 10px; /* Add padding for better spacing */
  border: 1px solid #ccc; /* Add border */
  border-radius: 5px; /* Rounded corners */
  background-color: #f9f9f9; /* Light background */
  color: #333; /* Text color */
  font-size: 16px; /* Font size */
  outline: none; /* Remove focus outline */
  transition: border-color 0.3s ease-in-out;
}

.selectDropdown:hover {
  border-color: #007bff; /* Change border color on hover */
}

.selectDropdown:focus {
  border-color: #007bff; /* Change border color on focus */
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5); /* Add focus shadow */
}
