.editMsgContainer {
  margin-bottom: 1rem;
  padding: 0.2rem;
  border: 1px solid #ccc;
  border-radius: 0.2rem;
}

.editMsg {
  color: red;
  font-size: 0.8rem;
  font-weight: 400;
  text-transform: uppercase;
  text-align: center;
  letter-spacing: 1.5px;
}
