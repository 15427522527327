
.tableContainer {
  width: 100%;
  max-width: 900px;
  border-collapse: collapse;
  border: 1px solid #ccc;
  /* background-color: red; */
}

.tableContainer th,
.tableContainer td {
  padding: 8px;
  text-align: left;
  border: 1px solid #ccc;
}

.tableContainer th {
  background-color: #f2f2f2;
}

.tableBodyContainer tr:nth-child(even) {
  background-color: #f2f2f2;
}

.btn {
  outline: none;
  border: none;
  padding: 0.3rem 0.6rem;
  border-radius: 0.2rem;
  /* font-size: 1.2rem; */
  text-transform: uppercase;
  background-color: #333;
  color: #fff;
  cursor: pointer;
}
