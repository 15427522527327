/* Container styles */
.inputControlBox {
    /* width: 100%; */
    max-width: 1100px;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #f9f9f9;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    /* border: 1px solid red; */
  }
  
  /* Header and label styles */
  .label {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
    color: #333;
  }
  
  .selectedPlansBox {
    margin-bottom: 20px;
    padding: 10px;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    background-color: #fff;
  }
  
  .selectedPlansBox p {
    margin: 0;
    font-size: 16px;
    color: #555;
  }
  
  /* Checkbox group styles */
  .checkboxGroup {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 20px;
    /* border: 1px solid red; */
  }

    
  .checkboxLabel {
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 16px;
    color: #444;
    cursor: pointer;
    /* border: 1px solid red; */
    border-radius: 0.2rem;
    padding: 0.2rem;
    
  }
  .checkboxLabel.inactive {
    background-color: rgba(240, 129, 129, 0.623);
  }
  
  .checkboxLabel input[type="checkbox"] {
    width: 18px;
    height: 18px;
    cursor: pointer;
  }
  
  
  /* Reset button styles */
  .resetButton {
    display: inline-block;
    padding: 5px 10px;
    font-size: 16px;
    /* font-weight: bold; */
    color: #fff;
    background-color: #ff4d4f;
    text-transform: uppercase;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .resetButton:hover {
    background-color: #e60026;
  }
  

  .plansWrapper {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* 3 equal columns */
    gap: 1rem; /* Spacing between items */
    width: 100%; /* Full width */
  }
  
  .checkboxLabel {
    display: flex;
    align-items: center; /* Align checkbox and label text vertically */
    padding: 0.2rem 0.5rem ;
    border: 1px solid #ccc; /* Optional border for better visibility */
    border-radius: 4px; /* Rounded corners */
    background-color: #f9f9f9; /* Light background color */
    transition: background-color 0.3s ease, transform 0.2s ease;
    cursor: pointer;
  }
  
  .checkboxLabel:hover {
    background-color: #e0f7fa; /* Highlight on hover */
    transform: translateY(-2px); /* Slight lift on hover */
  }
  
  .inactive {
    background-color: #f5f5f5; /* Different background for inactive plans */
    /* color: #aaa;  */
    /* cursor: not-allowed; */
  }
  
  .inactive input {
    pointer-events: none; /* Disable checkbox interaction for inactive plans */
  }
  
  input[type="checkbox"] {
    margin-right: 0.5rem; /* Space between checkbox and label text */
  }
  
  .selectAllLabel {
    width: 25%;
    background-color: rgb(173, 240, 72);
  }