/* @import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,700;1,800;1,900&display=swap"); */
@import url("https://fonts.googleapis.com/css2?family=Oswald:wght@200;300;400;500;600;700&family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-color: rgba(0, 0, 0, 0.1); */
}

* {
  margin: 0;
  padding: 0;
  /* font-family: "Poppins", sans-serif; */
  font-family: "Oswald", "Rubik", sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
  text-decoration: none;
}

button:disabled,
button[disabled] {
  /* border: 1px solid #999999; */
  background-color: #cccccc;
  color: #666666;
  pointer-events: none;
}

.topHeadingUnderline {
  border-bottom: 1px solid #999;
  padding-left: 0.5rem;
  padding-bottom: 0.2rem;
  margin-bottom: 2rem;
}

.commonTextInput {
  width: 60%;
  outline: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  height: 30px;
  padding: 0 0.5rem;
}

.mainHeading {
  display: inline-block;
  margin: 1rem;
  padding: 0.5rem 1rem;
  text-transform: uppercase;
  font-weight: 500;
  border: 1px solid #999;
  border-radius: 0.2rem;
}