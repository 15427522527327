
.compContainer {
  max-width: 800px;
  --borderRadius: 0.2rem;
  --statusOpenColor: greenyellow;
  --statusClosedColor: #666;
  --statusInProgressColor: orange;
  --statusDeferredColor: #ccc;
}

.pageTitle {
  text-align: center;
  margin: 1rem;
  text-transform: uppercase;
  font-weight: 400;
}

.ticket_id {
  display: inline-block;
  margin-top: 1rem;
  padding: 0.2rem;
  border: 1px solid #999;
  border-radius: var(--borderRadius);

  text-transform: uppercase;
  font-size: 0.9rem;
}

.ticket_id span {
  font-weight: bold;
}

.statusPriorityContainer {
  margin-top: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.ticketStatusTitle {
  /* margin: 1rem 0; */

  /* border: 2px solid greenyellow; */
  border-radius: var(--borderRadius);
  padding: 0.5rem;
}
.ticketStatusTitle.Open {
  border: 2px solid var(--statusOpenColor);
}
.ticketStatusTitle.Closed {
  border: 2px solid var(--statusClosedColor);
}
.ticketStatusTitle.InProgress {
  border: 2px solid var(--statusInProgressColor);
  /* background-color: rgb(241, 187, 85); */
}
.ticketStatusTitle.Deferred {
  border: 2px solid var(--statusDeferredColor);
}
.ticketStatusTitle span,
.priorityTitle span {
  padding: 0.2rem 0.5rem;
  background-color: orange;
  color: #333;
  font-weight: bold;
  border-radius: 0.2rem;
  /* border: 1px solid #ccc; */
}

.priorityTitle span {
  background-color: #666;
  color: #fff;
}


.ticketStatusTitle.Open span {
  background-color: var(--statusOpenColor);
}
.ticketStatusTitle.Closed span {
  background-color: var(--statusClosedColor);
}
.ticketStatusTitle.InProgress span {
  background-color: var(--statusInProgressColor);
}
.ticketStatusTitle.Deferred span {
  background-color: var(--statusDeferredColor);
  /* color: #c2fbcc; */
}

.changeTicketStatusContainer {
  display: flex;
  gap: 1rem;
}
.ticketStatusTitle p,
.priorityTitle p {
  text-transform: uppercase;
}
.changeTicketStatusContainer select {
  outline: none;
  border: 1px solid #ccc;
  border-radius: 0.2rem;
  padding: 0.4rem 1rem;
  font-weight: bold;
  cursor: pointer;
}


.saveBtnContainer {
  margin: 2rem 0 1rem;
}
.setStatusBtn {
  outline: none;
  border: 1px solid #ccc;
  border-radius: 0.2rem;
  padding: 0.2rem 1rem;
  font-weight: bold;
  font-size: 1.2rem;
  cursor: pointer;

  background-color: #90EE90;
}
.setStatusBtn:hover {
  background-color: #666;
  color: #fff;
}


/* ticket status container */
.ticketStatusContainer {
  border: 1px solid #ccc;
  border-radius: 0.4rem;
  max-width: 300px;
  margin: 1rem;
  padding: 1rem;
}
.ticketStatusContainer h3 {
  text-align: center;
  /* margin: 1rem 0; */
  padding: 0.2rem 0;
  text-transform: uppercase;
  font-weight: 400;
  border: 1px solid #ccc;
  border-radius: 0.2rem;
}

.statusPriorityInputContainer {
  display: flex;
  justify-content: space-between;
  margin: 1rem 0;
}

/* STATUS & PRIORITY CONTAINER END */


/* file download attachment */
.downloadFileContainer {
  max-width: 300px;
  margin: 1rem;
  padding: 0.5rem;
  text-transform: uppercase;
  border: 1px solid #ccc;
  border-radius: var(--borderRadius);

  display: flex;
  justify-content: space-evenly;
}


.descriptionContainer {
  max-width: 600px;
  margin: 2rem;
}

.descriptionBox {
  /* border: 1px solid red; */

  max-width: 600px;
  /* margin: 1rem; */
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 0.4rem;
}

.createdDate {
  text-align: right;
  font-size: 0.8rem;
  color: #333;
  font-weight: bold;
}

.msgInputBoxContainer,
.msgResponseBox {
  max-width: 600px;
  margin: 1rem;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 0.4rem;
  
}

/* MessageBox component */
.msgResponseBox {
  background-color: #e2e2e2;
  /* background-color: #339502; */
  color: #333;
}
.msgResponseBox.admin {
  background-color: #c2fbcc;
  color: #333;
  transform: translateX(30px);

  position: relative;
}
.msgResponseBox.user {
  /* background-color: red; */
}
.msgResponseBox.admin:before {
  position: absolute;
  top: -15px;
  left: 10px;
  content: "";
  width: 0px;
   height: 0px;
   border-style: solid;
   border-width: 0 10px 15px 10px;
   border-color: transparent transparent #c2fbcc transparent;
}

.msgResponseHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem;
  border-bottom: 1px solid #ccc;
}
.msgResponseContent {
  padding: 0.5rem;
}

.msgInputBoxContainer {
  /* background-color: red; */
  /* padding: 1rem; */
  /* padding-bottom: 0.4rem; */
  /* max-height: 200px; */
}

.msgInputText {
  width: 100%;
  max-height: 100px;
  padding: 1rem;
  outline: none;
  border: none;
  /* border: 1px solid #ccc; */
  resize: none;
}






/* button */
.btnContainer {
  padding: 0.4rem;
  border-radius: 0.4rem;
  background-color: #ccc;
  display: flex;
  justify-content: flex-end;
}

.postBtn {
  outline: none;
  border: none;
  padding: 0.2rem 0.8rem;
  text-transform: uppercase;
  cursor: pointer;
  border-radius: 0.4rem;
  /* background-color: #aaa;
  color: white; */
  box-shadow: 2px 2px 4px #000;
}

.postBtn:hover {
  background-color: #333;
  color: #fff;
}