.access-denied {
    text-align: center;
    background-color: #f2dede;
    padding: 30px;
    margin-top: 50px;
    border-radius: 5px;
}

.access-denied__title {
    font-size: 2em;
    color: #a94442;
    margin-bottom: 20px;
}

.access-denied__message {
    font-size: 1.5em;
    color: #a94442;
}

.backBtn {
    margin-top: 1rem;
    padding: 0.2rem 1rem;
    background-color: #a94442;
    color: #fff;
    text-transform: uppercase;
    letter-spacing: 1.5px;
    font-size: 1.1rem;
    /* font-weight: 300; */
    border: 1px solid #999;
    border-radius: 0.3rem;
    cursor: pointer;
}

.backBtn:hover {
    background-color: #4e0b0a;
}