
.Footer {
  background-color: #e4ffe9;
  /* padding: 4rem 2rem 1rem; */
  /* background-color: cornflowerblue; */
  
  position: relative;
}

/* SUBSCRIBE & NEWSLETTER */
.subscribeContainer {
  min-width: 680px;
  height: 5rem;
  position: absolute;
  /* top: -3rem; */
  top: -4.5rem;
  left: 50%;
  transform: translate(-50%);
  /* width: 100%; */
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 2rem;
  /* border: 1px solid; */
  padding: 2rem;

  background-color: white;
  border-radius: 10px;
  background: #FFF;
  box-shadow: 0px -5px 16.8px 0px rgba(0, 0, 0, 0.25);

  display: none;
  
}
.subscribeContainer .title {
  flex: 1;
}
.emailContainer {
  flex: 1;
  display: flex;
}
.emailInput,
.subscribeBtn {
  /* height: 30px; */
  border-radius: 5px;
  padding: 0.5rem;
}
.emailInput {
  outline: none;
  width: 100%;
  margin-right: 0.5rem;
  border: 1px solid #DBDBDB;
  
}
.subscribeBtn {
  border: none;
  outline: none;
  /* padding: 0.5rem; */
  text-transform: uppercase;
  background: #339502;
  color: white;
  font-weight: 300;
  letter-spacing: 1px;
  cursor: pointer;
}


.topContainer {
  color: rgba(43, 43, 43, 1);
  display: flex;
  justify-content: center;
  /* align-items: center; */
  flex-wrap: wrap;
  border-bottom: 2px solid rgba(63, 177, 5, 0.40);
  padding: 4rem 2rem 1rem;
}

/* FOOTER SECTION COMMON STYLING */
.footerSection {
  padding: 2rem;
}
.sectionTitle {
  color: #083A5E;
  margin-bottom: 1rem;
  text-align: center;
}

/* SOCIAL LINKS SECTION */
.socialLinksContainer {
  flex: 1;
  
}
.usefulLinksContainer {
  flex: 1;
  
}

.subscribeContainer {
  /* flex: 1; */
}

.socialLinksContainer h1 {
  margin-bottom: 1rem;
}
.socialLinksContainer p {
  min-width: 250px;
  /* color: #909090; */
  /* color: rgba(43, 43, 43, 1); */
  text-align: justify;
  margin-bottom: 2rem;
}

.socialIcons {
  display: flex;
  gap: 2rem;
}

/* MAKE A CALL SECTION */
.phoneContainer {
  display: flex;
  align-items: center;
  /* justify-content: center; */
  gap: 1rem;
}
.phoneIconContainer{
  
}
.phoneText span {
  font-size: 0.8rem;
}

/* CONTACT US SECTION */
.contactWrapper {
  color: rgba(43, 43, 43, 1);
  display: flex;
  flex-direction: column;
}
.contactItem {
  margin: 0.5rem 0;
}
.iconContainer {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-bottom: 0.3rem;
}
.iconContainer .title {
  font-weight: 400;
}
.contactItem .text {
  font-size: 0.9rem;
}


/* USEFULL LINKS & MENU SECTION  */
.listWrapper {
  display: flex;
  /* align-items: center; */
  justify-content: space-evenly;
  gap: 1rem;
}

.linksList {
  list-style: none;
  
}

.link {
  color: #444;
  
}
.linkItem {
  margin: 0.2rem 0;
  /* padding-bottom: 0.5rem; */
  
  transition: all .5s;
}

.linkItem:hover {
  /* border-bottom: 1px solid; */
  color: blue;
}


/* SUBSCRIBE SECTION */
/* .subscribeContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
} */

.formContainer {
  max-width: 300px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.subscribeButton {
  height: 40px;
  border-radius: 5px;
  outline: none;
  border: none;
}

/* .emailInput {
  
  padding-left: 1rem;
  background: #F0F0F0;
} */

.subscribeButton {
  background-color: #339502;
  color: white;
}


/* COPYRIGHT */
.copyrightContainer {
  padding: 1rem;
  text-align: center;
  color: #fff;
  background-color: #85B97C;
}


/* RESPONSIVE MEDIA QUERIES */
@media only screen and (max-width: 800px) {
  .subscribeContainer {
    /* background-color: lightblue; */
    /* align-items: normal; */
    flex-direction: column;
    min-width: fit-content;
    /* min-width: 400px; */
    min-width: 80%;
    height: fit-content;
  }

  .subscribeContainer .title {
    text-align: center;
  }

  .emailContainer {
    width: 80%;
  }
}

@media only screen and (max-width: 480px) {
  .subscribeContainer {
    top: -6rem;
  }

  .emailContainer {
    width: 100%;
  }
}