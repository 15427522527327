

.tinyMCEContainer {
  max-width: 1300px;
  display: flex;
  flex-wrap: wrap;
  /* border: 1px solid red; */
}

.tinyMCEEditor {
  max-width: 800px;
}

.tinyMCEPreviewContainer {
  margin: 2rem;
  border: 1px solid #ccc;
  width: 400px;
  height: 600px;
}

.tinyMCEPreviewContainer h2 {
  margin: 1rem;
  text-align: center;
}

.btnContainer {
  margin: 1rem;
  display: flex;
  gap: 1rem;
}
.btnContainer button {
  outline: none;
  border: none;
  padding: 0.2rem 0.5rem;
  border-radius: 0.2rem;
  text-transform: uppercase;
  cursor: pointer;
}

.clearBtn {
  background-color: orangered;
  color: #fff;
}
.publishBtn {
  background-color: blue;
  color: #fff;
}

.clearBtn:hover {
  background-color: #111;
}
.publishBtn:hover {
  background-color: #111;
}