.subscriptionFormContainer,
.formControl {
  border: 1px solid #ccc;
  border-radius: 0.2rem;
}

.subscriptionFormContainer {
  width: 400px;
  max-width: 500px;
  margin: 1rem;
  padding: 1rem;

  background-color: #eee;
}

.formTitle {
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 1rem;
  text-align: center;
  text-transform: uppercase;
}

.formGroup {
  margin: 0.8rem 0;
  text-transform: uppercase;

  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #ccc;
  padding-bottom: 0.5rem;
  /* padding: 0.5rem 0; */
}

.formControl {
  outline: none;
  border: none;
  padding: 0.4rem;
  /* border-bottom: 1px solid #ccc; */
  width: 60%;
  box-shadow: 2px 2px 4px #888888;
}

.btnContainer {
  margin: 1.5rem 0 0.5rem;
  display: flex;
  justify-content: center;
}

.btn {
  outline: none;
  border: none;
  padding: 0.3rem 0.6rem;
  border-radius: 0.2rem;
  font-size: 1.2rem;
  text-transform: uppercase;
  background-color: #333;
  color: #fff;
  cursor: pointer;
}
