.cartPage {
  /* display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;
  font-family: Arial, sans-serif; */
  margin-bottom: 4rem;
}

.planDetails {
  margin-bottom: 20px;
  /* text-align: center; */
}

.addonDetails {
  margin-bottom: 20px;
  text-align: center;
}

.addonDetails h3 {
  margin-bottom: 10px;
}

.addonDetails label {
  display: block;
  margin: 5px 0;
}

.total {
  text-align: center;
}

/* THE CART, NEW DESIGN */
.main_Container {
  /* padding: 0 2rem; */
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 1rem;
  max-width: 100%;
}

.cart_Container,
.coupon_Container,
.bottom_Price_Container,
.payment_container {
  border: 1px solid #ccc;
  border-radius: 4px;

  padding: 2rem 1rem;
  margin: 1rem 0;
}

.cart_Container {
  width: 100%;
  min-width: 300px;
  padding: 1rem 2rem;
  flex: 5;
}

.right_price_Container {
  flex: 3;
}


.bottom_Price_Container {
  padding: 1rem;
}


.price_container,
.total_price_container {
  display: flex;
  justify-content: space-between;
  
}

.price_container:not(:last-child) {
  margin-bottom: 0.2rem;
}

.total_price_container {
  border-top: 1px solid;
  border-bottom: 1px solid;
}

.payable_amount {
  font-weight: bold;
}

/* PAYMENT CONTAINER, RAZORPAY, PAYPAL */

/* COUPONS LIST */
.couponList {
  list-style-type: none;
}

.couponItem {
  display: inline-block;
  padding: 0.1rem 0.6rem;
  margin: 0.2rem;
  border: 2px solid #ccc;
  border-radius: 2rem;
  font-size: 0.8rem;
  cursor: pointer;
}
