.TradeDetailsTable {
    margin: 2rem;
  }
  
  .tableContainer {
    width: 100%;
    max-width: 1600px;
    border-collapse: collapse;
    border: 1px solid #ccc;
    /* background-color: red; */
  }
  
  .tableContainer th,
  .tableContainer td {
    padding: 8px;
    text-align: left;
    border: 1px solid #ccc;
  }
  
  .tableContainer th {
    background-color: #f2f2f2;
  }
  
  .tableBodyContainer tr:nth-child(even) {
    background-color: #f2f2f2;
  }
  
  .tableBodyContainer tr.statusClosed {
    /* background-color: #a9a9a9; */
    background-color: #e2e2e2;
    /* color: #fff; */
  }
  
  .tableBodyContainer tr:hover {
    /* background-color: #e2e2e2; */
    background-color: #a9a9a9;
    color: #111;
  }
  
  .colorCircleContainer {
    /* border: 1px solid red; */
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .editBtn {
    padding: 0.2rem 0.6rem;
    text-transform: uppercase;
    cursor: pointer;
  }
  .editBtn:disabled,
  .editBtn[disabled] {
    border: 1px solid #999999;
    background-color: #cccccc;
    color: #666666;
    cursor: not-allowed;
  }
  