

.userSubscriptionDetailsContainer {
  margin: 2rem;
  padding: 2rem 1rem;
  max-width: 450px;
  border: 1px solid #ccc;
  border-radius: 0.5rem;
}

.userSubscriptionDetailsContainer h2 {
  text-align: center;
  margin-bottom: 1rem;
}

.detailItem {
  display: flex;
  align-items: center;
  justify-content: space-between;

  border-bottom: 1px solid #ccc;
}
.detailItem h3 {
  font-weight: 300;
  text-transform: uppercase;
}

.detailItem.highlightRedBg {
  background-color: rgba(221, 84, 84, 0.3);
  text-transform: uppercase;
  /* font-size: 0.8rem; */
  padding: 0.4rem 0;
}

.btnContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.btn {
  cursor: pointer;
  border: none;
  outline: none;
  margin-top: 1rem;
  padding: 0.3rem 0.5rem;
  border-radius: 0.2rem;
  text-transform: uppercase;
  font-size: 1rem;
  /* font-weight: bold; */
  transition: all 0.2s ease-in-out;
  color: #333;
}

.renewBtn {
  /* color: #333; */
  background-color: rgba(11, 218, 81, 1);
}
.renewBtn:hover {
  color: #e2e2e2;
  background-color: rgb(27, 107, 54);
}


.cancelBtn {
  /* color: #333; */
  background-color: rgb(233, 110, 110);
}
.cancelBtn:hover {
  background-color: rgb(128, 12, 12);
  color: #e2e2e2;
}

.changePlanBtn {
  /* color: #333; */
  background-color: rgb(55, 184, 223);
}
.changePlanBtn:hover {
  background-color: rgb(32, 130, 160);
  color: #e2e2e2;
}