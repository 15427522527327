

.tabItem {
  display: inline-block;
  padding: 0.2rem 0.5rem;
  border: 1px solid #000;
  border-right: none;
  text-transform: uppercase;
  cursor: pointer;

  font-size: 1.2rem;
}

.tabItem:hover {
  background-color: rgb(52, 124, 219);
  color: #fff;
}

.tabItem:first-child {
  border-radius: 0.5rem 0 0 0;
}
.tabItem:last-child {
  border-radius: 0 0.5rem 0 0;
  border-right: 1px solid #000;
}