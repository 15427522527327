.mainContainer {
  width: 100vw;
  height: 100vh;
}

.formContainer {
  max-width: 350px;
  margin: 0 auto;
  margin-top: 4rem;
  padding: 2rem;
  border: 3px solid #ccc;
  border-style: double;
  border-radius: 0.2rem;
  background-color: rgb(243, 242, 242);
}

.title,
.subtitle {
  text-align: center;
}

.subtitle {
  margin: 1rem 0;
  text-transform: uppercase;
  letter-spacing: 4px;
  font-weight: 400;
}

.inputBox {
  margin: 0.5rem 0;
  display: flex;
  /* align-items: center; */
  justify-content: space-between;
}

.mobileInput,
.mobileOTP {
  /* border: none; */
  padding: 0.5rem;
  outline: none;
  border: 1px solid #ccc;
  border-radius: 0.2rem;
  width: 60%;
}

.buttonContainer {
  margin: 1rem 0;
  width: 100%;
  display: flex;
  justify-content: center;
}

.submitButton {
  outline: none;
  padding: 0.2rem 0.5rem;
  /* background-color: #aaa;
  color: white; */

  border: 1px solid #ccc;
  border-radius: 0.2rem;
  cursor: pointer;
}

.submitButton:hover {
  background-color: #ccc;
}