/* .tableContainer th,
.tableBodyContainer td {
  margin: 0;
  padding: 0;
  border: 1px solid #ccc;
} */

/* module.css */

.tableContainer {
  width: 100%;
  border-collapse: collapse;
  border: 1px solid #ccc;
}

.tableContainer th,
.tableContainer td {
  padding: 8px;
  text-align: left;
  border: 1px solid #ccc;
}

.tableContainer th {
  background-color: #f2f2f2;
}

.tableBodyContainer tr:nth-child(even) {
  background-color: #f2f2f2;
}

.actionBtnContainer button {
  margin-right: 5px;
  padding: 5px 10px;
  gap: 10px;
  background-color: #007bff;
  color: #fff;
  border: none;
  cursor: pointer;
}

.actionBtnContainer button:hover {
  background-color: #0056b3;
}
