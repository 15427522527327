
.outerFormWrapper {
    /* border: 1px solid red; */
    max-width: 1600px;
    display: flex;
    gap: 2rem;
}


.StockAdvisoryForm {
    margin: 2rem;
    /* border: 2px solid red; */
    --border-color: #999;
    --border-radius: 0.2rem
}


.formWrapper {
    border: 1px solid #ccc;
    border-radius: 0.2rem;
    width: 400px;
    padding: 2rem;
    background-color: #e9e9e9;
}

.formWrapper h2 {
    text-align: center;
    font-weight: 500;
    text-transform: uppercase;
    border: 1px solid #333;
    border-radius: 0.2rem;
    margin-bottom: 1rem;
}

.selectedSymbolInput {
    outline: none;
    width: 50%;
    border: 1px solid var(--border-color);
    border-radius: var(--border-radius);
}

.inputControlBox {
    /* border: 1px solid green; */
    margin: 1rem 0;
    display: flex;
    justify-content: space-between;
}

.inputControlBox input,
.inputControlBox textarea {
    outline: none;
    border: 1px solid var(--border-color);
    border-radius: var(--border-radius);
    padding: 0.2rem;
}
.inputControlBox textarea {
    width: 60%;
    resize: none;
}

.advisoryTypeOptions {
    display: flex;
    gap: 0.5rem;
    flex-direction: column;
}

.label {
    text-transform: uppercase;
}

.advisoryDuration {
    border: 1px solid var(--border-color);
    border-radius: var(--border-radius);
    width: 40%;
    text-align: center;
    background-color: #333;
    color: #fff;
    font-weight: 400;
    /* padding: 0.2rem 1.2rem; */
}

.btnContainer {
    display: flex;
    gap: 1rem;
    align-items: center;
    justify-content: center;
}

.cancelEditBtn,
.submitBtn,
.clearBtn {
    outline: none;
    border: 1px solid var(--border-color);
    border-radius: 0.2rem;
    padding: 0.2rem 0.6rem;
    
    cursor: pointer;
    text-transform: uppercase;
}
.submitBtn {
    background-color: greenyellow;
}

.submitBtn:hover {
    background-color: rgb(78, 126, 7);
    color: #fff;
}

.cancelEditBtn {
    background-color: rgb(238, 105, 56);
}
.cancelEditBtn:hover {
    background-color: rgb(131, 42, 10);
    color: #fff;
}

.clearBtn {
    /* background-color: rgb(240, 104, 55); */
    border: none;
    background-color: #ff4d4f;
    color: #fff;
    /* letter-spacing: 1px; */
    /* font-weight: bold; */
}
.clearBtn:hover {
    /* background-color: rgb(160, 48, 8); */
    background-color: #e60026;
    /* color: #fff; */
}


@keyframes blink {
    0% {
        opacity: 1;
    }

    50% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.blinkingText {
    text-align: center;
    /* margin-top: 20%; */
    font-size: 20px;
    font-weight: 400;
    letter-spacing: 1px;
    color: red;
    text-transform: uppercase;
    animation: blink 1s infinite;
}