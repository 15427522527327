.SinglePaymentDetails {
  /* border: 1px solid red; */
  padding: 2rem;
}

.logoContainer {
  width: 100px;
  margin: 1rem 0;
}
.logo {
  width: 100%;
}

.addressContainer {
  /* border: 1px solid red; */
  margin: 1rem 0;
}

.addressList {
  list-style: none;
  font-size: 0.9rem;
}

.invoiceNumberContainer {
  margin: 1rem 0;
}

.paymentDetails {
  /* border: 1px solid red; */
}

.paymentDetails div {
  max-width: 400px;
  padding: 0.5rem 0;

  display: flex;
  justify-content: space-between;
}

.dateContainer {
  border-top: 1px solid black;
}
.amount {
  /* border-bottom: 1px solid black; */
}
.total {
  border-top: 1px solid black;
  border-bottom: 1px solid black;
  font-weight: bold;
}

.miscDetails {
  margin: 2rem 0;
}
.miscDetails p {
  margin-bottom: 0.5rem;
}