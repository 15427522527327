.add-member-wrappper{
  margin: 10px;
  background-color: rgba(208, 235, 135, 0.305);
  border-radius: 8px;
  padding: 10px;
}

.add-member-fields-container {
  display: flex;
}

.team-member-action {
margin-top: 20px;
}