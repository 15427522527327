.mainContainer {
    margin: 2rem;
}

.tableTitle {
    text-align: center;
    margin-bottom: 1rem;
  }
  
  .tableContainer {
    width: 100%;
    max-width: 1400px;
    border-collapse: collapse;
    border: 1px solid #ccc;
    /* background-color: red; */
  }
  
  thead th {
    text-transform: uppercase;
  }
  
  .tableContainer th,
  .tableContainer td {
    padding: 8px;
    text-align: left;
    border: 1px solid #ccc;
  }
  
  .tableContainer th {
    background-color: #f2f2f2;
  }
  
  .tableBodyContainer tr:nth-child(even) {
    /* background-color: #f2f2f2; */
  }

  .deleteBtnContainer {
    /* border: 1px solid red; */
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .deleteIcon {
    cursor: pointer;
  }