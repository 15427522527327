.marqueeForm {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1.5rem;
  background: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  max-width: 800px;
  margin: 2rem;
  padding: 1rem;
  margin-left: 1rem;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 0.4rem;
}

.inputContainer {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.label {
  font-size: 1rem;
  font-weight: bold;
  color: #333;
}

.input,
.textarea {
  padding: 0.8rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1rem;
  width: 100%;
  transition: border-color 0.3s;
}

.input:focus,
.textarea:focus {
  border-color: #007bff;
  outline: none;
}

.createButton {
  padding: 0.8rem 1.5rem;
  background: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
  transition: background 0.3s;
}

.createButton:hover {
  background: #0056b3;
}

.disabledButton {
  background: #ccc;
  cursor: not-allowed;
}

:root {
  --primary-color: #007bff;
  --secondary-color: #6c757d;
  --danger-color: #dc3545;
  --bg-color: #f8f9fa;
  --text-color: #333;
  --border-color: #ddd;
}

.marqueeList {
  padding: 1rem;
  background: var(--bg-color);
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.table {
  display: grid;
  gap: 1rem;
}

.tableHeader,
.tableRow {
  display: grid;
  grid-template-columns: 0.5fr 1.5fr 2fr 1fr 1fr 0.5fr;
  align-items: center;
  padding: 0.8rem;
  background: #fff;
  border: 1px solid var(--border-color);
  border-radius: 4px;
}

.tableHeader {
  font-weight: bold;
  color: var(--primary-color);
  background: var(--bg-color);
}

.tableRow:nth-child(odd) {
  background: #fefefe;
}

.tableRow:hover {
  background: #f1f1f1;
}

.marqueeDescCell {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.deleteIcon {
  width: 20px;
  height: 20px;
  fill: var(--danger-color);
  cursor: pointer;
  transition: transform 0.2s ease, fill 0.2s ease;
}

.deleteIcon:hover {
  transform: scale(1.2);
  fill: #a71d2a;
}

.emptyMessage {
  text-align: center;
  color: var(--secondary-color);
  font-size: 1.2rem;
  margin-top: 1rem;
}

.screenListContainer {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;
  background: var(--bg-color);
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.screenLabel {
  font-weight: bold;
  color: var(--text-color);
  margin-bottom: 0.5rem;
}

.selectScreenInput {
  padding: 0.6rem;
  font-size: 1rem;
  color: var(--text-color);
  border: 1px solid var(--border-color);
  border-radius: 4px;
  background: #fff;
  transition: border-color 0.2s ease;

  /* Responsive dropdown */
  width: 100%;
  max-width: 300px;
}

.selectScreenInput:focus {
  outline: none;
  border-color: var(--primary-color);
  box-shadow: 0 0 4px rgba(0, 123, 255, 0.25);
}

.btnContainer {
  display: flex;
  gap: 1rem;
  margin-top: 1rem;
}

.createContentBtn {
  padding: 0.6rem 1.2rem;
  font-size: 1rem;
  color: #fff;
  background-color: var(--primary-color);
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.createContentBtn:hover {
  background-color: darken(var(--primary-color), 10%);
}

.createContentBtn:disabled {
  background-color: var(--secondary-color);
  cursor: not-allowed;
}

/* Uncomment styles for additional buttons if needed */
.editContentBtn {
  padding: 0.6rem 1.2rem;
  font-size: 1rem;
  color: #fff;
  background-color: var(--success-color);
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.editContentBtn:hover {
  background-color: darken(var(--success-color), 10%);
}

.deleteContentBtn {
  padding: 0.6rem 1.2rem;
  font-size: 1rem;
  color: #fff;
  background-color: var(--danger-color);
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.deleteContentBtn:hover {
  background-color: darken(var(--danger-color), 10%);
}

/* .marqueeForm {
  max-width: 800px;
  margin: 2rem;
  padding: 1rem;
  margin-left: 1rem;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 0.4rem;
} */

.inputBox {
  /* border: 1px solid red; */
  margin: 0.5rem 0;
  display: flex;
  justify-content: space-between;
}

.nameInput,
.descInput {
  outline: none;
  width: 70%;
  border: 1px solid #ccc;
  border-radius: 0.2rem;
}

.descInput {
  margin: 0.5rem 0;
  padding: 0.4rem;
  resize: none;
  width: 100%;
}

.marqueeCreateBtn {
  border: none;
  outline: none;
  padding: 0.2rem 0.5rem;
  margin-top: 1rem;
  cursor: pointer;

  border: 1px solid #ccc;
  border-radius: 0.3rem;
}

/* MARQUEE TABLE LIST */
.marqueeList {
  margin: 2rem;
  padding: 1rem;
  margin-left: 1rem;
  max-width: 1100px;
  border: 1px solid #ccc;
  border-radius: 0.5rem;
}

.marqueeItem {
  width: 100%;
  display: flex;
  gap: 0.5rem;
  /* justify-content: space-evenly; */
}
.marqueeDescCell {
  width: 70%;
  padding: 0.5rem;
  padding-top: 0;
  text-align: justify;
  font-size: 0.9rem;
  font-weight: 400;
  /* border: 1px solid red; */
}
.dateContainer {
  margin: 0.5rem 0;
  max-width: 200px;
  display: flex;
  gap: 0.5rem;
  justify-content: space-between;
}

.deleteIcon {
  cursor: pointer;
}

/* tinyce editor */

.screenListContainer {
  max-width: 200px;
  margin: 1rem;
  padding: 0.5rem 1rem;
  border: 1px solid #ccc;
  border-radius: 0.3rem;

  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  /* justify-content: space-between; */
}

.selectScreenInput {
  outline: none;
  border: 1px solid #999;
  border-radius: 0.3rem;
}

.createContentBtn {
  background: linear-gradient(135deg, var(--primary-color), #0056b3);
  color: white;
  font-size: 1rem;
  font-weight: 600;
  padding: 10px 20px;
  border-radius: var(--radius);
  border: none;
  cursor: pointer;
  transition: all 0.3s ease;
}
.createContentBtn:hover {
  background: linear-gradient(135deg, #0056b3, #00408a);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

/* Disabled Button */
.createContentBtn:disabled {
  background-color: var(--secondary-color);
  cursor: not-allowed;
}
.createPostWrapper {
  /* max-width: 800px; */
  padding: 1rem;
}

.editorContainer {
  background: #ffffff;
  border-radius: var(--radius);
  padding: 20px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  margin: 20px auto;
}

h2 {
  color: var(--text-color);
  font-size: 1.5rem;
  margin-bottom: 15px;
  border-left: 4px solid var(--primary-color);
  padding-left: 10px;
}

/* Flex container for label and select input */
.selectContainer {
  /* display: flex; */
  /* align-items: center; */
  /* gap: 10px; */
  /* margin-bottom: 10px; */
}

.screenLabel {
  font-weight: 600;
  font-size: 1rem;
  color: var(--text-color);
  white-space: nowrap;
}

/* Input and Select Styling */
.selectScreenInput,
.inputControl {
  flex: 1;
  /* padding: 10px; */
  border-radius: var(--radius);
  border: 1px solid var(--border-color);
  font-size: 1rem;
  transition: border-color 0.3s ease;
  background: #fff;
  /* min-width: 200px; */
}

.selectScreenInput:focus,
.inputControl:focus {
  border-color: var(--primary-color);
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
  outline: none;
}

/* Button Container */
.btnContainer {
  display: flex;
  gap: 1rem;
  justify-content: flex-start;
  margin-top: 15px;
}




