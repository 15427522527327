.date {
  padding: 0 0.3rem;
  border: 1px solid green;
  border-radius: 0.3rem;
}

.sectorTagWrapper {
  max-width: 400px;
  height: auto;
  padding: 0.2rem 0;
  margin: 0.5rem 0;
  /* border: 1px solid red; */

  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
}

.sectorTagItem {
  padding: 0 0.4rem;
  border: 1px solid #ccc;
  border-radius: 0.2rem;

  text-transform: uppercase;
  font-size: 0.7rem;
  font-weight: 500;
  cursor: pointer;
}

.sectorTagItem:hover {
  background-color: #666;
  color: #fff;
}


.previousRecommendationsBtn {
  cursor: pointer;
  border: none;
  outline: none;
  border-radius: 0.2rem;
  margin: 1rem 0;
  padding: 0.5rem 1rem;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 1.2rem;
  font-weight: 300;
  background-color: rgb(3, 166, 109);
  color: #fff;
}