.tabsList {
  margin: 1rem;
}

.tabItem {
  border: 1px solid #76dd43;
  /* margin: 0.5rem; */
  padding: 0.5rem 1rem;
  text-transform: uppercase;

  cursor: pointer;
}
.selected {
  background-color: #76dd43;
}
.notActive {
  pointer-events: none;
  cursor: not-allowed;
}

.tabItem:hover {
  background-color: #76dd43;
  color: #333;
}
