@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;600&display=swap');


.container {
  width: 100%;
  /* max-width: 400px; */
  /* min-width: 550px; */
  /* height: 100%; */
  overflow: auto;
  /* padding: 2rem 0.2rem; */
  padding: 1rem 0;
  /* padding: 1rem; */
  padding-bottom: 0;
  /* max-width: 800px;
  padding: 0 10px;
  margin: 0 auto;
  margin-top: 70px; */
  font-family: 'Poppins', sans-serif;
  /* padding: 1rem;
  margin-bottom: 2rem;
  overflow: none; */
  /* display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; */
  border-radius: 6px;
background: #FFF;
box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.08);
}

.select_segment_container {
  /* margin: 1px 0; */
  display: flex;
  justify-content: flex-end;
  /* align-items: center; */
}

.topContainer {
  display: flex;
  justify-content: space-between;
  gap: 0.5rem;
  margin-bottom: 0.5rem;
  padding: 0 0.5rem;
}

.titleContainer,
.shareContainer {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  /* justify-content: space-between; */
  flex-wrap: wrap;
}

.title {
  /* text-align: center; */
  font-size: 18px;
  font-weight: 400;
  text-transform: uppercase;
  /* background-color: #f5f5f5; */
  /* color: #4dcb0f; */
}


/* TABLE STYLING */
.tableContainer {
  min-width: 550px;
  height: 550px;
  /* padding: 2rem; */
  overflow: scroll;
}

.table {
  width: 100%;
  border-collapse: collapse;
  border: 0;
  color: rgba(0,0,0,0.8);
  
  position: relative;
}





.tableRow:nth-child(even) {
  background-color: #f9f9f9;
}

.tableRow th,
.tableRow td {
  padding: 0.6rem 0.6rem;
  border: 1px solid #e2e2e2;
}

.tableRow th {
  /* font-size: 18px; */
  font-weight: 500;
  /* padding: 6px; */
  
  /* text-align: left; */
  background-color: #e2e2e2;
  text-transform: uppercase;
  /* background-color: #339502; */
  
}

/* TABLE HEADER STICKY */
.table th {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 1;
  /* padding: 4px; */
  /* border: -4px solid #e2e2e2 !important; */
  
}

.tableRow td {
 }

.tableRow td:hover {
  background-color: #fffaf3;
}

.symbolName {
  font-weight: bold;
  font-size: 0.9rem;
}



/* MEDIA QUERIES */
@media only screen and (max-width: 1280px) {
  .container {
    padding: 0;
  }

  .topContainer {
    padding: 0.5rem 1rem;
  }

  .tableContainer {
    min-width: 550px;
  }
}

@media only screen and (max-width: 600px) {
  .container {
    padding: 0;
  }

  .topContainer {
    padding: 0.5rem 1rem;
  }

  .tableContainer {
    min-width: 100%;
    height: 550px;
    
  }
}