
.select_Input {
  text-align: center;
  height: 35px;
  padding: 0 0.2rem;
  border-radius: 4px;
  outline: none;

  text-align: left;
  /* width: 120px; */
  border: 1px solid #ccc;
  /* margin-bottom: 4px; */
}


.select_Title {
  margin-bottom: 8px;
}