.tableContainer {
  width: 100%;
  max-width: 1000px;
  border-collapse: collapse;
  border: 1px solid #ccc;
  /* background-color: red; */
}

.tableContainer th,
.tableContainer td {
  padding: 8px;
  text-align: left;
  border: 1px solid #ccc;
}

.tableContainer th {
  background-color: #f2f2f2;
}

.tableBodyContainer tr:nth-child(even) {
  background-color: #f2f2f2;
}
