
.summaryModalContainer {
    min-width: 350px;
}

.modalTitle {
    color: #fff;
    background-color: #333;
    margin: 1.5rem 0;
    padding: 0.2rem 0.5rem;
    /* display: inline-block; */
    font-weight: 300;

    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    text-align: center;
    text-transform: capitalize;
    border: 1px solid #333;
    border-radius: 0.2rem;
}

/* SYMBOL ITEM */
.symbolNameContainer {
    margin: 0.5rem 0;
    padding: 0 0.4rem;
    padding-bottom: 0.2rem;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #999;
}
.symbolNameContainer .labelName {
    text-transform: uppercase;
}
.symbolNameContainer .labelValue {
    /* text-transform: uppercase; */
    font-weight: bold;
}

.publishBtnContainer {
    margin: 2rem 0 0;
    display: flex;
    gap: 0.5rem;
    justify-content: center;
}

.cancelBtn,
.publishBtn {
    outline: none;
    border: 1px solid #666;
    border-radius: 0.2rem;
    padding: 0.4rem 1rem;
    text-transform: uppercase;
    cursor: pointer;
}

.publishBtn:hover {
    background-color: #32CD32;
    /* color: #fff; */
}
.cancelBtn:hover {
    background-color: #FF3131;
    color: #fff;
}