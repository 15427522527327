.searchBoxContainer {
  display: flex;
  border-radius: 0.2rem;
  margin: 1rem;
  border: 1px solid #ccc;
  padding: 0.5rem;
  width: 380px;
  justify-content: space-around;
  align-items: center;
}
.filterCard {
  margin-bottom: 10px;
  max-width: 1200px;
}
.filterButton {
  padding: 0.2rem;
  border: none;
  border-radius: 0.2rem;
  background-color: #007bff;
  color: white;
  cursor: pointer;
  font-size: 0.9rem;
}

.filterButton:hover {
  background-color: #0056b3;
}
.searchQueryInput {
  padding: 0.2rem;
  border: 1px solid #999;
  border-radius: 0.2rem;
  width: 70%;
}
.clearBtn {
  padding: 0.2rem 0.4rem;
  text-transform: uppercase;
  cursor: pointer;
}

/* TABLE STYLES */

.tableTitle {
  margin-bottom: 1rem;
}

.tableContainer {
  min-width: 1200px;
  /* max-width: 1100px; */
}

.table {
  border-collapse: collapse;
  /* width: 100%; */
}

/* Table Header Styles */
.table thead {
  background-color: #f2f2f2;
}

/* Table header & body styles */
.table th,
.table td {
  padding: 6px 12px;
  text-align: left;
  border: 1px solid #dddddd;
}
.tableRow:hover {
  background-color: greenyellow;
}

.refundBtn {
  outline: none;
  border: 1px solid #b30000;
  border-radius: 0.3rem;
  /* padding: 0.4rem 0.8rem; */
  padding: 0.2rem 0.4rem;
  text-transform: uppercase;
  background: linear-gradient(145deg, #ff3333, #cc0000);
  color: #fff;
  letter-spacing: 1px;
  font-weight: 300;
  cursor: pointer;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
  transition: all 0.2s ease-in-out;
}

.refundBtn:hover {
  background: linear-gradient(145deg, #cc0000, #a30000);
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.4);
  transform: translateY(-2px);
}

.refundBtn:active {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  transform: translateY(1px);
}
