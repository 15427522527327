
.pricing_plan_outer {
  margin: 2rem 0;
  
}

.titleContainer {
  text-align: center;
  margin-bottom: 1rem;
}

.pricing_plan_container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.PricingTileNew {
  display: flex;
  flex-direction: row;
}

.features_list_column,
.free_tier_column,
.basic_tier_column,
.premium_tier_column {
  flex: 1;
  min-width: 200px;
  max-width: 300px;
  
}

.pricing_plan_container li {
  border-bottom: 1px solid #ccc;
  border-right: 1px solid #ccc;
  height: 60px;
}

.pricing_plan_container li:first-child {
  border-top: 1px solid #ccc;
}

.features_list_column li {
  display: flex;
  align-items: center;
  /* justify-content: center; */
  padding-left: 2rem;
  border-left: 1px solid #ccc;
  text-transform: uppercase;
}

/* make first column heading center */
.features_list_column .heading_list_item {
  justify-content: center;
  padding-left: 0;
  
}

.free_tier_column li,
.basic_tier_column li,
.premium_tier_column li {
  display: flex;
  align-items: center;
  justify-content: center;
}


li.heading_list_item {
  height: 130px;
  flex-direction: column;
  
}

.plan_title {
  text-transform: uppercase;
  font-weight: 300;
  letter-spacing: 2px;
}

.price_span {
  color: #666;
  font-size: 0.9rem;
  font-weight: 400;
}

.choose_plan_button {
  border: none;
  outline: none;
  margin-top: 0.2rem;
  padding: 0.2rem 2rem;
  background-color: #333;
  color: white;
  border-radius: 2px;
  text-transform: capitalize;
  cursor: pointer;
}

.choose_plan_button:hover {
  background-color: #222;
  color: white;
}


/* FEATURES LIST HEADING, CAPTION */
.features_list_title_container {
  width: 100%;
  padding: 0 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: space-between; */
}

.features_list_title {
  font-size: 1.2rem;
  font-weight: bold;
}

.offer_text {
  font-size: 0.7rem;
  padding: 0 0.4rem;
  border: 1px solid;
  border-radius: 20px;
}

.features_list_caption {
  text-transform: none;
  font-size: 0.8rem;
  padding: 0 1rem;
  margin-top: 0.5rem;
  color: #666;
}