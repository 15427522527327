/* TAble styling */

.outerContainer {
  margin: 2rem;
}

.tableTitle {
  text-align: center;
  margin-bottom: 1rem;
}

.tableContainer {
  width: 100%;
  max-width: 900px;
  border-collapse: collapse;
  border: 1px solid #ccc;
  /* background-color: red; */
}

.tableContainer th,
.tableContainer td {
  padding: 8px;
  text-align: left;
  border: 1px solid #ccc;
}

.tableContainer th {
  background-color: #f2f2f2;
}

.tableBodyContainer tr:nth-child(even) {
  background-color: #f2f2f2;
}

.btn,
.viewBtn {
  outline: none;
  border: 1px solid #333;
  padding: 0.3rem 0.6rem;
  border-radius: 0.2rem;
  /* font-weight: bold; */
  /* font-size: 1.2rem; */
  text-transform: uppercase;
  background-color: #98FB98;
  color: #333;
  cursor: pointer;
}

.viewBtn:hover {
  background-color: #2b682b;
  color: #fff;
}