
.backBtn {
    margin: 1rem;
    border: 1px solid #ccc;
    padding: 0.2rem 0.5rem;
    border-radius: 0.2rem;

    font-weight: bold;
    color: #333;
    background-color: #eee;
    text-transform: uppercase;
    cursor: pointer;
}

.backBtn:hover {
    background-color: #333;
    color: #fff;
}