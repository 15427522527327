.mainContainer {
    min-width: 300px;
}

.lastNameContainer,
.emailContainer {
    text-transform: uppercase;
    margin: 1rem 0;
    display: flex;
    justify-content: space-between;
}

.saveBtnContainer {
    /* border: 1px solid red; */
    margin-top: 2rem;
    display: flex;
    justify-content: center;
}

.saveBtn {
    outline: none;
    border: 1px solid #ccc;
    padding: 0.3rem 2rem;
    color: #333;
    font-size: 1rem;
    font-weight: bold;
    letter-spacing: 2px;
    text-transform: uppercase;
    border-radius: 4px;
    cursor: pointer;
}

.saveBtn:hover {
    background-color: #333;
    color: #fff;
}