/* Global Styles for the whole app */


/* BLINKING BACKGROUND ANIMATION */
@keyframes blinking {
  0% {
    background-color: #fff;
    /* border-top: 2px solid #5fed9d;
    border-bottom: 2px solid #5fed9d; */
    /* color: #fff; */
    /* border: 1px solid #666; */
  }
  100% {
    /* background-color: rgb(111, 235, 192); */
    background-color: #baf1d2;
    /* color: #fff; */
    /* border: 1px solid #666; */
  }
}
.blink_background {
  /* animation: blinking 2s infinite; */
  animation-name: blinking;
  animation-duration: 0.8s;
  animation-iteration-count: infinite;
  animation-direction: alternate-reverse;
  animation-timing-function: ease-in-out;
}